import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { CarouselDesktop } from 'common/components/Carousel/CarouselDesktop';
import { getFilteredClusters } from 'common/components/Carousel/helpers';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import {
  selectAllEntries,
  selectClusterById,
} from 'common/redux/commonData/entries/selectors';
import { selectTopicsByClusters } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import { selectTopicPageClusterIds } from 'common/redux/pages/topic/selectors';
import { PuidsType } from 'config/constants/common';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

type GalleryPropsType = {
  clusterId: ClusterData['id'] | undefined;
  clusterIndex: number;
  clusterPuids: PuidsType | undefined;
};

/**
 * Галерея кластера с описанием и ссылками на источники фото
 * @param clusterId - id кластера
 * @param clusterIndex - индекс кластера
 * @param clusterPuids - рекламные пуиды кластера
 */
export const Gallery = memo((props: GalleryPropsType) => {
  const { clusterId, clusterIndex, clusterPuids } = props;

  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
  const clusterFeed = useSelector(selectClusterPageClusterFeed, shallowEqual);
  const topicClusterIds = useSelector(selectTopicPageClusterIds, shallowEqual);
  const entries = useSelector(selectAllEntries, shallowEqual);

  const top100Attribute = useTop100AttributeWithValue('gallery');

  const filteredClusters = getFilteredClusters({
    clusterFeed,
    topicClusterIds,
    entries,
  });

  const topicsByClusters = useAppSelector(
    selectTopicsByClusters(filteredClusters),
    shallowEqual,
  );

  const recommendedClusters = filteredClusters.map((item) => ({
    ...item,
    topicInfo: topicsByClusters.find((topic) => topic?.id === item.mainTopicId),
  }));

  return (
    <figure className={s.gallery} {...top100Attribute}>
      <CarouselDesktop
        cluster={cluster}
        clusterIndex={clusterIndex}
        clusterPuids={clusterPuids}
        recommendedClusters={recommendedClusters}
      />
    </figure>
  );
});
