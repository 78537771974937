import React, { memo } from 'react';

import { Ad } from 'common/components/Ad';
import {
  AdLoadingState,
  useAdLoadStatus,
} from 'common/components/Ad/hooks/useAdLoadStatus';
import { RcmWidget } from 'common/components/RCMWidget';
import {
  WidgetLayoutTypeV3,
  WidgetTemplate,
} from 'common/components/RCMWidget/typings';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';

const AD_RENDER_STYLES = { backgroundColor: 'white' };

type AdSuperfooterWithFallbackPropsType = {
  puids: PuidsType | undefined;
  index: number;
  clusterId: CardData['id'];
};

/**
 * Блок суперфутера с заглушкой в виде блока рнета.
 * @param props.puids - пуиды, настройки для рекламы;
 * @param props.clusterId - id кластера.
 */
export const AdSuperfooterWithFallback = memo(
  ({ puids, clusterId }: AdSuperfooterWithFallbackPropsType) => {
    const { handleComplete, adStatus } = useAdLoadStatus();

    if (adStatus === AdLoadingState.Error) {
      return (
        <RcmWidget
          rcmBlockType={RCM_BLOCK_TYPE.rnetHorizontalDesktop}
          templateName={WidgetTemplate.v3}
          layoutType={WidgetLayoutTypeV3.Horizontal}
          clusterId={clusterId}
        />
      );
    }

    return (
      <Ad
        name={Banners.Superfooter}
        puids={puids}
        loadedStyle={AD_RENDER_STYLES}
        onComplete={handleComplete}
        withoutMarginBottom
      />
    );
  },
);
