import React, { useState, useEffect, useCallback, useRef, memo } from 'react';

import { RamblerButton } from 'common/components/RamblerComponents/RamblerButton';
import { RamblerPopup } from 'common/components/RamblerComponents/RamblerPopup';

import { RENDER_ID, TITLE, SETTINGS_MAP, BLUR_VALUE } from './config';

import s from './styles.module.css';

export type PrivatePopupPropsType = {
  clusterID: CardData['id'];
  title?: string;
  blur?: number;
  blurSelector?: string;
};

/**
 * Функция установки блюра на dom-ноду
 * @param ref - ref на дом ноду, к которой применится блюр
 * @param blur - значение блюра
 */
const setBlur = (
  ref: React.MutableRefObject<HTMLElement | null>,
  blur: number,
) => {
  const blurValue = `blur(${blur}px)`;

  if (ref?.current) {
    ref.current.setAttribute(
      'style',
      `-webkit-filter: ${blurValue}; filter: ${blurValue}`,
    );
  }
};

/**
 * Попап валидации доступа к контенту
 * @param clusterID - ID кластера для поиска в мапке
 * @param title - заголовок попапа
 * @param blur - значение блюра
 * @param blurSelector - селектор dom-ноды, к которой применится блюр
 */
export const PrivatePopup = memo(
  ({
    clusterID,
    title = TITLE,
    blur = BLUR_VALUE,
    blurSelector = RENDER_ID,
  }: PrivatePopupPropsType) => {
    const redirectLink = clusterID ? SETTINGS_MAP[clusterID] : '';
    const isActive = Boolean(redirectLink);

    const rootRef = useRef<HTMLElement | null>(null);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
      if (isActive) {
        rootRef.current = document.querySelector(blurSelector);
        setBlur(rootRef, blur);
        setOpenModal(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClose = useCallback(() => {
      if (redirectLink) {
        document.location.href = redirectLink;
      }
    }, [redirectLink]);

    const onShow = useCallback(() => {
      setOpenModal(false);
      setBlur(rootRef, 0);
    }, []);

    return (
      <RamblerPopup
        isOpen={openModal}
        closeOnEsc={false}
        closeOnClickOutside={false}
        onClose={onClose}
        title={title}
        className={s.popup}
      >
        <div className={s.controls}>
          <RamblerButton onClick={onShow}>Да</RamblerButton>
          <RamblerButton onClick={onClose} type="secondary">
            Нет
          </RamblerButton>
        </div>
      </RamblerPopup>
    );
  },
);
