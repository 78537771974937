import cn from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';

import { CommentsButton } from 'common/components/buttons/CommentsButton';
import { TagList } from 'common/components/NewTagList';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectIsHumanCenteredStrategyStateActivated } from 'common/redux/runtime/selectors';
import { CLUSTER_NAME_TYPES } from 'config/constants/cluster';
import {
  CLUSTER_BUTTONS_PLACES,
  ShareButton,
} from 'desktop/pages/Cluster/components/ShareButton';
import { useAppSelector } from 'store/hooks';

import stylesTagList from './stylesTagList.module.css';

import s from './styles.module.css';

type ClusterButtonsPropsType = {
  clusterId: ClusterData['id'];
  clusterIndex: number;
};

/**
 * Компонент с кнопками для футера кластера.
 * @param clusterId – id кластера;
 * @param clusterIndex – индекс кластера.
 */
export const ClusterButtons = ({
  clusterId,
  clusterIndex,
}: ClusterButtonsPropsType) => {
  const cluster = useAppSelector(selectClusterById(clusterId), shallowEqual);
  const forseRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const top100Attribute = useTop100AttributeWithValue('bottom');

  const { url = '', title = '', image, type, noComments } = cluster || {};

  const dataRamblerShare =
    typeof type === 'number'
      ? `cluster_${CLUSTER_NAME_TYPES[type]}::${clusterIndex + 1}`
      : '';

  return (
    <div className={s.container} {...top100Attribute}>
      <TagList clusterId={clusterId} styles={stylesTagList} />
      <div className={cn(s.wrapper, !noComments && s.wrapper_mod)}>
        <ShareButton
          clusterId={clusterId}
          clusterUrl={url}
          clusterTitle={title}
          clusterImageUrl={image?.url}
          dataRamblerShare={dataRamblerShare}
          componentPlace={CLUSTER_BUTTONS_PLACES.bottom}
          customStyles={s}
        />
        <CommentsButton
          className={cn(s.commentsBtn, forseRedesign && s.redesign)}
          countClassName={cn(s.commentsCounter, forseRedesign && s.redesign)}
          clusterId={clusterId}
          top100="comments_button::bottom"
          forceSPA
        />
      </div>
    </div>
  );
};
