import { shallowEqual, useSelector } from 'react-redux';

import {
  selectWidgetsBreakingNewsClusterInCardData,
  selectWidgetsBreakingNewsHasBreakingNews,
  selectWidgetsBreakingNewsLoadingState,
} from 'common/redux/commonData/widgets/breakingNewsWidget/selectors';

type UseBreakingNewsWidgetType = (projectId: ProjectType['id']) => {
  /**
   * Id кластера, по которому загружаем информацию для карточки
   */
  hasBreakingNews: boolean;
  /**
   * Кластер для отображения
   */
  cluster: CardData | undefined;
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
};

/**
 * Хук для виджета срочной новости
 */
export const useBreakingNewsWidget: UseBreakingNewsWidgetType = () => {
  const hasBreakingNews = useSelector(selectWidgetsBreakingNewsHasBreakingNews);
  const cluster = useSelector(
    selectWidgetsBreakingNewsClusterInCardData,
    shallowEqual,
  );
  const isLoading = useSelector(selectWidgetsBreakingNewsLoadingState);

  return {
    hasBreakingNews,
    cluster,
    isLoading,
  };
};
