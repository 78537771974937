import React, { memo } from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { datesince } from 'utils/date/dtime';

import s from '../../styles.module.css';

type CardItemPropsType = {
  url: CardData['url'];
  title: CardData['title'];
  topic: CardData['topic'];
  publicationTime: CardData['publicationTime'];
  commentsCount: number | undefined;
};

/**
 * Компонент новости для виджета со срочной новостью.
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.topic - топик кластера;
 * @param props.publicationTime - время публикации;
 * @param props.commentsCount - количество комментариев в кластере.
 */
export const CardItem = memo(
  ({
    url,
    title,
    topic,
    publicationTime,
    commentsCount,
  }: CardItemPropsType) => {
    const top100AttributeWithValue = useTop100AttributeWithValue(`item::1`);

    return (
      <a className={s.item} href={url} {...top100AttributeWithValue}>
        <p>
          {title}
          <span className={s.meta}>
            <span className={s.topic}>{topic}</span>
            <span className={s.time}>
              {!!publicationTime &&
                datesince({
                  pubdate: new Date(publicationTime).getTime() / 1000,
                })}
            </span>
            <CommentsCounter count={commentsCount} className={s.comments} />
          </span>
        </p>
      </a>
    );
  },
);
