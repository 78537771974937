import cn from 'classnames';
import React, { ReactNode, memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { ClusterWasRead } from 'common/components/ClusterWasRead';
import { VideoAdIframe } from 'common/components/VideoAdIframe';
import { useClusterScroll } from 'common/hooks/useClusterScroll';
import { useCopyModifier } from 'common/hooks/useCopyModifier';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectVideoAd } from 'common/redux/appController/selectors';
import {
  selectClusterAutotags,
  selectClusterById,
  selectClusterPuids,
  selectClusterResource,
} from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import {
  selectDomainConfig,
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { PARAGRAPH_TYPE } from 'common/utils/clusterContent/config';
import { ParagraphConfigType } from 'common/utils/clusterContent/typings';
import {
  useParagraphsDesk,
  CUT_TYPE,
} from 'common/utils/clusterContent/useParagraphsDesk';
import Banners from 'config/constants/banner/banners';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import {
  checkIsAdlessMaterial,
  checkIsPartnerMaterial,
} from 'utils/checkIsPartnerMaterial';
import { generateClusterUrl } from 'utils/urlGenerator';

import s from './styles.module.css';

const INREAD_INSERT_CHAR_COUNT = 2000;

const adStyles = { marginBottom: '20px' };
const adStylesSponsored = { marginBottom: '3px' };

enum PARAGRAPH_LIST_TYPE {
  beforeCut,
  inCut,
}

type ClusterContentPropsType = {
  clusterIndex: number;
  clusterId: ClusterData['id'];
  children: ReactNode | undefined;
};

/**
 * Контент кластера
 * @param clusterId - id кластера;
 * @param clusterIndex -  Индекс кластера в списке отображаемых;
 * @param children - children.
 */
export const ClusterContent = memo(
  ({ clusterId, clusterIndex, children }: ClusterContentPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);

    const {
      body = '',
      isPaid,
      type: clusterType,
      draft,
      mainTopicId,
      manualTagIds,
      displayType,
      normalizedTitle = '',
    } = cluster || {};

    const domainConfig = useSelector(selectDomainConfig, shallowEqual);
    const projectId = useSelector(selectProjectId);
    const autotags = useSelector(
      selectClusterAutotags(clusterId),
      shallowEqual,
    );
    const textScaleValue = useSelector(selectClusterPageTextScale);
    const resource = useSelector(
      selectClusterResource(clusterId),
      shallowEqual,
    );
    const topic = useSelector(selectTopicById(mainTopicId), shallowEqual);
    const videoAd = useSelector(selectVideoAd, shallowEqual);
    const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);
    const isMulticluster = useSelector(
      selectManualTagIsMulticluster(manualTagIds, displayType),
    );
    const forceRedesign = useSelector(
      selectIsHumanCenteredStrategyStateActivated,
    );

    const top100Attribute = useTop100AttributeWithValue('top');
    const clusterContentRef = useCopyModifier<HTMLDivElement>(
      cluster,
      topic?.alias,
    );

    const absoluteClusterUrl = useMemo(
      () =>
        generateClusterUrl({
          domainConfig,
          clusterId,
          normalizedTitle,
          topic,
        }),
      [domainConfig, clusterId, normalizedTitle, topic],
    );

    const { id: resourceId = 0 } = resource ?? {};

    const withFilterTags = PROJECT_IDS.News !== projectId;

    const { paragraphs, adcenter, inread } = useParagraphsDesk({
      clusterBody: body,
      autotags,
      draft,
      options: {
        cut: CUT_TYPE.NO_CUT,
        isLong: false,
        withFilterTags,
        isAppDesign: true,
      },
    });

    const { metrikaRefEnd, metrikaRefPart, metrikaRefInCutEnd } =
      useClusterScroll({
        cluster,
        clusterIndex,
      });

    const { paragraphsListBeforeCut, paragraphsListInCut } = paragraphs;

    let isInreadInsert = false;
    let isSpeakableIdAdd = false;

    const hasTextInCut = paragraphsListInCut.length > 0;

    let inreadBanner: ReactNode = null;
    let adcenterBanner: ReactNode = null;

    const memoVideoAdIframe = useMemo(
      () => (
        <VideoAdIframe
          clusterUrl={absoluteClusterUrl}
          embedCode={videoAd.embedCode}
          puids={puids}
          clusterId={clusterId}
          className={s.videoAd}
        />
      ),
      [absoluteClusterUrl, clusterId, puids, videoAd.embedCode],
    );

    const renderParagraphs = (
      paragraphList: ParagraphConfigType[],
      listType: PARAGRAPH_LIST_TYPE,
    ) => {
      const isBeforeCut = listType === PARAGRAPH_LIST_TYPE.beforeCut;

      const isInCut = listType === PARAGRAPH_LIST_TYPE.inCut;

      return paragraphList.map((paragraph, index, list) => {
        const lastParagraph = list.length - 1 === index;
        // Отображаем первые два параграфа или сразу все параграфы, если кластер первый
        const { text, config, Component, type, accumulatedLength } = paragraph;

        // Нельзя вставлять баннер, если выше и ниже его есть embed
        const isNearEmbedParagraph =
          type === PARAGRAPH_TYPE.EMBED ||
          paragraphsListBeforeCut?.[index + 1]?.type === PARAGRAPH_TYPE.EMBED;

        // Нельзя вставлять баннер, если выше и ниже его есть картинка или видео
        const isNearMediaParagraph =
          type === PARAGRAPH_TYPE.MEDIA ||
          paragraphsListBeforeCut?.[index + 1]?.type === PARAGRAPH_TYPE.MEDIA;

        const canInsertBanners = isBeforeCut
          ? !isNearEmbedParagraph && !isNearMediaParagraph && !lastParagraph
          : !isNearEmbedParagraph && !isNearMediaParagraph;

        // Размечаем для speakable только параграфы с текстом
        const getSpeakableId = () => {
          if (
            !isSpeakableIdAdd &&
            type === PARAGRAPH_TYPE.TEXT &&
            isBeforeCut
          ) {
            isSpeakableIdAdd = true;

            return { id: 'summary' };
          }

          return {};
        };

        // Вставка в параграф inread при соблюдении условий
        inreadBanner = null;

        if (
          inread &&
          !isInreadInsert &&
          accumulatedLength > INREAD_INSERT_CHAR_COUNT &&
          canInsertBanners &&
          !checkIsPartnerMaterial(resourceId) &&
          !checkIsAdlessMaterial(clusterId)
        ) {
          isInreadInsert = true;
          inreadBanner = <Ad isLazy name={Banners.Inread} puids={puids} />;
        }

        // Вставка в параграф adcenter, если он второй, а всего параграфов 4 или более
        adcenterBanner = null;

        if (
          adcenter &&
          index === 1 &&
          (clusterIndex === 0
            ? paragraphsListBeforeCut.length >= 4
            : paragraphsListInCut.length >= 2) &&
          canInsertBanners &&
          isBeforeCut &&
          !checkIsPartnerMaterial(resourceId) && // не выводим рекламу и видео дня для партнерских материалов https://jira.rambler-co.ru/browse/NEWS-10457
          !checkIsAdlessMaterial(clusterId)
        ) {
          adcenterBanner = (
            <Ad
              isLazy
              name={Banners.Adcenter}
              puids={puids}
              loadedStyle={adStyles}
              className={s.adcenter}
              bannerReplacementSlot={
                CLUSTER_TYPE.video === clusterType ? null : memoVideoAdIframe
              }
            />
          );
        }

        return (
          <React.Fragment key={index}>
            <div
              className={cn(s.paragraph, s[`paragraph_${textScaleValue}`], {
                [s.paragraphBeforeCut]: hasTextInCut && isBeforeCut,
              })}
              {...(!isSpeakableIdAdd && getSpeakableId())}
            >
              <Component
                text={text}
                config={config}
                clusterIndex={clusterIndex}
                clusterUrl={absoluteClusterUrl}
                containerClass={s.embedWrapper}
                puids={puids}
                topic={topic}
                paragraphIndex={index}
                isMulticluster={isMulticluster}
              />
            </div>
            {adcenterBanner}
            {inreadBanner}
            {index === 0 && isBeforeCut && children}
            {isInCut && paragraphList.length - 1 === index && (
              <div ref={metrikaRefInCutEnd} />
            )}
          </React.Fragment>
        );
      });
    };

    return (
      <div
        ref={clusterContentRef}
        className={cn(
          s.content,
          s[`content_${textScaleValue}`],
          isMulticluster && s.multicluster,
          forceRedesign && s.redesignCluster,
        )}
        {...top100Attribute}
      >
        {renderParagraphs(
          paragraphsListBeforeCut,
          PARAGRAPH_LIST_TYPE.beforeCut,
        )}

        {hasTextInCut && <div ref={metrikaRefPart} />}

        <div className={cn(s.cut, { [s.cut__open]: !hasTextInCut })}>
          {renderParagraphs(paragraphsListInCut, PARAGRAPH_LIST_TYPE.inCut)}

          {isPaid && <Ad isLazy name={Banners.Paid} puids={puids} />}

          <ClusterWasRead clusterId={clusterId} />
        </div>

        {!hasTextInCut && <div ref={metrikaRefEnd} />}
        <Ad
          name={Banners.Sponsored}
          puids={puids}
          className="banner--sponsored"
          style={adStylesSponsored}
        />
      </div>
    );
  },
);
