import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';

import { NarrowColumnContext } from './context';

import s from './styles.module.css';

export enum NARROW_AREA_TYPES {
  widgets = 'widgets',
  aside = 'aside',
}

export type NarrowColumnPropsType = {
  children: React.ReactNode;
  isSticky?: boolean;
  className?: string;
};

/**
 * Дополнительная колонка, для виджетов или рекламы
 * @param isSticky – флаг липкого контента;
 * @param className - строка с классом для дополнительной стилизации.
 */
export const NarrowColumn = withErrorBoundary(function NarrowColumn({
  children,
  isSticky = false,
  className,
}: NarrowColumnPropsType) {
  const columnRef = useRef<HTMLDivElement>(null);
  const [fullHeight, setFullHeight] = useState(0);

  useEffect(() => {
    // Вычисляем свободное место в колонке, чтобы передавать в виджеты и там определять умещается ли виджет подписки
    const columnHeight = columnRef.current?.clientHeight ?? 0;

    setFullHeight(columnHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnRef.current?.clientHeight]);

  const value = useMemo(() => ({ fullHeight }), [fullHeight]);

  return (
    <NarrowColumnContext.Provider value={value}>
      <div
        className={cn(s.narrowColumn, className, isSticky && s.sticky)}
        ref={columnRef}
      >
        <div>{children}</div>
      </div>
    </NarrowColumnContext.Provider>
  );
});
