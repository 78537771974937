import React, { memo, useContext, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ErrorBoundary } from 'common/components/ErrorBoundary';
import { RcmWidgetWithResource } from 'common/components/RCMWidget/components/RcmWidgetWithResource';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { WidgetGroupWithSubscribe } from 'desktop/components/Cluster/WidgetGroupWithSubscribe';
import {
  RCMNewsWidget,
  RCM_NEWS_WIDGET_HEIGHT_DEFAULT,
  TOP_NEWS_WIDGET_HEIGHT_DEFAULT,
  TopProjectNewsWidget,
} from 'desktop/components/TopNewsWidget';
import {
  BreakingNewsWidget,
  BREAKING_NEWS_WIDGET_HEIGHT_DEFAULT,
} from 'desktop/pages/Cluster/components/BreakingNewsWidget';
import { NarrowColumnContext } from 'desktop/pages/Cluster/components/ColumnsLayout/context';

type WidgetsSwitcherPropsType = {
  clusterIndex: number;
  clusterId: CardData['id'];
};

/** Отступ между блоками */
const GAP = 20;

/** Самая распространенная высота виджета, когда прилетают 2х-строчные заголовки */
export const RNET_WIDGET_HEIGHT_DEFAULT = 350;

/**
 * Обертка для виджета, определяет нужные виджеты в зависимости от индекса кластера в бесконечке
 * @param clusterIndex – индекс кластера в бесконечке
 * @param clusterId – id кластера
 */
export const WidgetsSwitcher = memo(
  ({ clusterIndex, clusterId }: WidgetsSwitcherPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);

    const { type, gallery, manualTagIds, displayType, resourceId } =
      cluster || {};

    const isMulticluster = useSelector(
      selectManualTagIsMulticluster(manualTagIds, displayType),
    );

    const context = useContext(NarrowColumnContext);
    const fullHeight = context?.fullHeight ?? 0;

    // КОСТЫЛЬ: чтобы предотвратить исчезание виджета – в теле кластера может находиться виджет квиза, который после загрузки схлопывается и уменьшает высоту
    // тоесть на момент загрузки квиза виджет вставляется, а после схлопывания свободного места не хватает для виджета
    // и при скролле (когда компонент ререндерится) виджет главных новостей исчезает
    // пример такого кластера https://woman.rambler.ru/stars/50160470-chem-udivili-zvezdy-na-tseremonii-gremmi-2023/
    const isInserted = useRef(false);

    const topProjectNewsWidgetRef = useRef<HTMLDivElement>(null);
    const rnetNewsWidgetRef = useRef<HTMLDivElement>(null);
    const breakingNewsWidgetRef = useRef<HTMLDivElement>(null);

    const hasGallery = type === CLUSTER_TYPE.photo && !!gallery;

    const rnetNewsWidgetHeight =
      rnetNewsWidgetRef.current?.clientHeight ?? RNET_WIDGET_HEIGHT_DEFAULT;

    const topProjectNewsWidgetHeight =
      topProjectNewsWidgetRef.current?.clientHeight ??
      TOP_NEWS_WIDGET_HEIGHT_DEFAULT;

    const isInsertBreakingNewsWidget =
      BREAKING_NEWS_WIDGET_HEIGHT_DEFAULT <=
      fullHeight - rnetNewsWidgetHeight - topProjectNewsWidgetHeight - GAP * 2;

    const isInsertRCMNewsWidget = hasGallery
      ? RCM_NEWS_WIDGET_HEIGHT_DEFAULT <=
        fullHeight - rnetNewsWidgetHeight - GAP
      : true;

    const breakingNewsWidgetHeight =
      breakingNewsWidgetRef.current?.clientHeight ?? 0;

    let isInsertTopNewsWidget = true;

    // если есть галерея то считаем свободную высоту, если нет то вставляем TopProjectNewsWidget всегда
    if (hasGallery && !isInserted.current) {
      isInsertTopNewsWidget =
        TOP_NEWS_WIDGET_HEIGHT_DEFAULT <=
        fullHeight - rnetNewsWidgetHeight - breakingNewsWidgetHeight - GAP * 2;

      isInserted.current = isInsertTopNewsWidget;
    }

    switch (clusterIndex) {
      case 0:
        return (
          <>
            <ErrorBoundary componentName="BreakingNewsWidget">
              {isInsertBreakingNewsWidget && (
                <BreakingNewsWidget ref={breakingNewsWidgetRef} />
              )}
            </ErrorBoundary>
            <ErrorBoundary componentName="TopProjectNewsWidget">
              {isInsertTopNewsWidget && (
                <TopProjectNewsWidget
                  ref={topProjectNewsWidgetRef}
                  level={clusterIndex + 1}
                />
              )}
            </ErrorBoundary>
            <ErrorBoundary componentName="RcmWidgetWithResource">
              {!isMulticluster && (
                <RcmWidgetWithResource
                  resourceId={resourceId}
                  clusterId={clusterId}
                />
              )}
            </ErrorBoundary>
          </>
        );
      case 1:
        return (
          <WidgetGroupWithSubscribe
            resourceId={resourceId}
            clusterId={clusterId}
            isMulticluster={isMulticluster}
            level={clusterIndex + 1}
          />
        );

      default:
        return (
          <>
            <ErrorBoundary componentName="RCMNewsWidget">
              {isInsertRCMNewsWidget && (
                <RCMNewsWidget clusterID={clusterId} level={clusterIndex + 1} />
              )}
            </ErrorBoundary>
            <ErrorBoundary componentName="RcmWidgetWithResource">
              {!isMulticluster && (
                <RcmWidgetWithResource
                  resourceId={resourceId}
                  clusterId={clusterId}
                />
              )}
            </ErrorBoundary>
          </>
        );
    }
  },
);
