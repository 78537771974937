import React, { useCallback, memo } from 'react';

import { OnSwipedDesktopType } from 'common/components/Carousel/types';
import { Top100AttributeType } from 'common/hooks/useTop100Attribute';
import { ArrowUpIcon } from 'icons';

type CarouselButtonPropsType = {
  top100Attribute: Top100AttributeType;
  direction: OnSwipedDesktopType['direction'];
  callbackSlide: OnSwipedDesktopType['callbackSlide'];
  onSwiped: ({ direction, callbackSlide }: OnSwipedDesktopType) => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Кнопка карусели.
 * @param top100Attribute - топ100 атрибут.
 */
export const CarouselButton = memo((props: CarouselButtonPropsType) => {
  const {
    top100Attribute,
    direction,
    callbackSlide,
    onSwiped,
    ...buttonProps
  } = props;

  const handleClick = useCallback(
    () => onSwiped({ direction, callbackSlide }),
    [callbackSlide, direction, onSwiped],
  );

  return (
    <button
      type="button"
      onClick={handleClick}
      {...buttonProps}
      {...top100Attribute}
    >
      <ArrowUpIcon />
    </button>
  );
});
