import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RecommendedClustersType } from 'common/components/Carousel/types';
import { Top100AttributeType } from 'common/hooks/useTop100Attribute';
import { selectDomainConfig } from 'common/redux/runtime/selectors';
import { ArrowUpIcon } from 'icons';
import { generateClusterUrl } from 'utils/urlGenerator';

type CarouselLinkPropsType = {
  recommendedClusters: RecommendedClustersType;
  top100Attribute: Top100AttributeType;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Ссылка карусели на первый рекомендуемый кластер.
 * @param recommendedClusters - рекомендательные кластера;
 * @param top100Attribute - топ100 атрибут.
 */
export const CarouselLink = memo((props: CarouselLinkPropsType) => {
  const { recommendedClusters, top100Attribute, ...linkProps } = props;

  const domainConfig = useSelector(selectDomainConfig, shallowEqual);

  const cluster = recommendedClusters[0];

  const href = generateClusterUrl({
    domainConfig,
    clusterId: cluster?.id,
    normalizedTitle: cluster?.normalizedTitle,
    topic: cluster?.topicInfo,
  });

  return (
    <a {...linkProps} href={href} {...top100Attribute}>
      Ещё фото
      <ArrowUpIcon />
    </a>
  );
});
