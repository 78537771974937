import { ROOT_ID_NAME } from 'config/constants/common';

/** ID элемента в dom, к которому применится блюр */
export const RENDER_ID = `#${ROOT_ID_NAME}`;

export const BLUR_VALUE = 5;

export const TITLE =
  'Являетесь ли вы специалистом сферы здравоохранения или представителем СМИ?';

/** Мапка ID, к которым применится попап и урл перехода при отказе */
export const SETTINGS_MAP: { [clusterID: string]: string } = {
  44097221: 'https://doctor.rambler.ru/',
};
