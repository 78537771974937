import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ArticleHeader } from 'common/components/ArticleHeader';
import { ClusterContentOnDraft } from 'common/components/ClusterContentOnDraft';
import { IncrementCountersTrigger } from 'common/components/IncrementCountersTrigger';
import { useClusterScroll } from 'common/hooks/useClusterScroll';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { Header } from 'common/pages/headers/ClusterHeader';
import {
  selectClusterById,
  selectClusterIsNewDraftFlag,
  selectClusterPuids,
} from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import { selectTopicById } from 'common/redux/commonData/topics/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { ArticleMediaSection } from 'desktop/pages/Cluster/components/ArticleMediaSection';
import { Gallery } from 'desktop/pages/Cluster/components/ArticleMediaSection/components/Gallery';
import { ClusterButtons } from 'desktop/pages/Cluster/components/ClusterButtons';
import { ClusterContent } from 'desktop/pages/Cluster/components/ClusterContent';

import { AdColumn } from '../AdColumn';
import { NarrowColumn } from '../NarrowColumn';
import { WidgetsColumn } from '../WidgetsColumn';

import s from './styles.module.css';

type ArticlePropsType = {
  clusterId: ClusterData['id'];
  index: number;
  isCurrent?: boolean;
};

/**
 * Компонент содержимого кластера.
 * @param clusterId - id отображаемого кластера.
 * @param index - индекс кластера в бесконечке.
 * @param isCurrent - флаг что на экране отображается текущий кластер
 */
export const Article = memo(function Article({
  clusterId,
  index,
  isCurrent = false,
}: ArticlePropsType) {
  const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
  const mainTopic = useSelector(
    selectTopicById(cluster?.mainTopicId),
    shallowEqual,
  );
  const puids = useSelector(selectClusterPuids(clusterId), shallowEqual);
  const isNewDraft = useSelector(selectClusterIsNewDraftFlag(clusterId));
  const isMulticluster = useSelector(
    selectManualTagIsMulticluster(cluster?.manualTagIds, cluster?.displayType),
  );

  const top100Attribute = useTop100AttributeWithValue('content_block');

  const hasGallery = cluster?.type === CLUSTER_TYPE.photo && !!cluster?.gallery;
  const isFirstCluster = index === 0;

  const { metrikaRefStart } = useClusterScroll({
    cluster,
    clusterIndex: index,
  });

  return (
    <article className={cn(s.root, hasGallery && s.rootWithGallery)}>
      {isCurrent && <Header clusterId={clusterId} />}

      <div ref={metrikaRefStart} />

      {/* Заголовок и галерея */}
      <div className={cn(s.header, hasGallery && s.headerWithGallery)}>
        <ArticleHeader clusterIndex={index} clusterId={clusterId} />
        {hasGallery && (
          <Gallery
            clusterId={clusterId}
            clusterPuids={puids}
            clusterIndex={index}
          />
        )}
      </div>

      {/* Основной контент */}
      <div className={s.mainContent} {...top100Attribute}>
        {isNewDraft ? (
          <ClusterContentOnDraft
            // @ts-expect-error: ¯\_(ツ)_/¯
            draft={cluster?.draft}
            clusterFeedListIndex={index}
            isMulticluster={isMulticluster}
          />
        ) : (
          <ClusterContent clusterId={clusterId} clusterIndex={index}>
            <IncrementCountersTrigger
              firstCluster={isFirstCluster}
              clusterID={clusterId}
              projectID={mainTopic?.project_id}
            />
            {!isMulticluster && (
              <ArticleMediaSection
                clusterIndex={index}
                clusterId={clusterId}
                hasGallery={hasGallery}
              />
            )}
          </ClusterContent>
        )}

        {isMulticluster && !isNewDraft && (
          <ArticleMediaSection
            clusterIndex={index}
            clusterId={clusterId}
            hasGallery={hasGallery}
          />
        )}

        <ClusterButtons clusterId={clusterId} clusterIndex={index} />
      </div>

      <NarrowColumn
        className={cn(
          s.widgetsColumn,
          hasGallery && s.widgetsColumnWithGallery,
        )}
      >
        <WidgetsColumn clusterId={clusterId} level={index + 1} />
      </NarrowColumn>

      <NarrowColumn className={s.adColumn} isSticky>
        <AdColumn clusterId={clusterId} clusterIndex={index} />
      </NarrowColumn>
    </article>
  );
});
