import React, { forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';

import { selectProjectId } from 'common/redux/runtime/selectors';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';
import { HotNewsIcon } from 'icons';

import { CardItem } from './components/CardItem';
import { useBreakingNewsWidget } from './hooks/useBreakingNewsWidget';

import s from './styles.module.css';

export const BREAKING_NEWS_WIDGET_HEIGHT_DEFAULT = 140;

/**
 * Виджет со срочной новостью.
 * @param ref – реф для контейнера виджета
 */
export const BreakingNewsWidget = memo(
  forwardRef<HTMLDivElement>((_props, ref) => {
    const projectId = useSelector(selectProjectId);
    const { hasBreakingNews, cluster } = useBreakingNewsWidget(projectId);

    if (!hasBreakingNews) {
      return null;
    }

    const widgetTitle = (
      <>
        <HotNewsIcon className={s.icon} />
        <span>Срочная новость</span>
      </>
    );

    return (
      <FeedWidgetLayout
        title={widgetTitle}
        top100Value="breaking_news"
        ref={ref}
        styles={s}
      >
        <CardItem
          url={cluster?.url || ''}
          title={cluster?.title || ''}
          topic={cluster?.topic}
          publicationTime={cluster?.publicationTime}
          commentsCount={cluster?.commentsCount}
        />
      </FeedWidgetLayout>
    );
  }),
);
