import { createContext, useContext } from 'react';

type State = {
  fullHeight: number;
};

const initialState = {
  fullHeight: 0,
};

/**
 * Контекст для передачи значений высоты содержимого кластера, для отрисовки баннеров и виджетов
 */
export const MainClusterContentSizeContext = createContext<State>(initialState);
export const MainClusterContentSizeContextProvider =
  MainClusterContentSizeContext.Provider;

export const useMainClusterContentSize = () => {
  const { fullHeight } = useContext(MainClusterContentSizeContext);

  return {
    fullHeight: fullHeight ?? initialState.fullHeight,
  };
};
