import cn from 'classnames';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { RcmWidgetWithResource } from 'common/components/RCMWidget/components/RcmWidgetWithResource';
import { useResizeObserver } from 'common/hooks/useResizeObserver';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import Banners from 'config/constants/banner/banners';
import { PuidsType } from 'config/constants/common';
import { NativeNewsWidget } from 'desktop/components/Cluster/NativeNewsWidget';

import { useMainClusterContentSize } from '../ClusterLayout/context';

import s from './styles.module.css';

const NATIVE_NEWS_WIDGET_HEIGHT = 230;
const AD_BANNER_HEIGHT = 620;

/** Отступ между блоками */
const GAP = 20;
const VK_WIDGET_HEIGHT = 220 + GAP;

type AdSwitcherPropsType = {
  puids: PuidsType | undefined;
  isCompact: boolean;
  clusterId: ClusterData['id'];
  resourceId: ClusterData['resourceId'];
  clusterIndex: number;
};

/**
 * Компонент, отвечающий за отрисовку блоков в колонке с рекламой, в зависимости от свободной высоты.
 * @param puids - рекламные пуиды;
 * @param isCompact - флаг компактной версии;
 * @param clusterId - id отображаемого кластера;
 * @param resourceId - id основного источника кластера.
 * @param clusterIndex - индекс кластера
 */
export const AdSwitcher = memo(
  ({
    puids,
    isCompact,
    clusterId,
    resourceId,
    clusterIndex,
  }: AdSwitcherPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const isMulticluster = useSelector(
      selectManualTagIsMulticluster(
        cluster?.manualTagIds,
        cluster?.displayType,
      ),
    );

    const [rnetWidgetHeight, setRnetWidgetHeight] = useState(0);

    const { fullHeight } = useMainClusterContentSize();

    const callback = useCallback((entries: ResizeObserverEntry[]) => {
      if (entries) {
        const { offsetHeight } = entries[0]?.target as HTMLElement;

        if (offsetHeight) {
          setRnetWidgetHeight(offsetHeight);
        }
      }
    }, []);

    const rnetWidgetRef = useResizeObserver<HTMLDivElement>(
      isCompact ? callback : null,
    );

    const VK_WIDGET_HEIGHT_BY_INDEX = clusterIndex === 0 ? VK_WIDGET_HEIGHT : 0;
    const FREE_HEIGHT_FOR_COMPACT =
      fullHeight -
      rnetWidgetHeight -
      AD_BANNER_HEIGHT -
      VK_WIDGET_HEIGHT_BY_INDEX -
      GAP * 2;
    const FREE_HEIGHT_FOR_FULL_SCREEN =
      fullHeight -
      AD_BANNER_HEIGHT -
      VK_WIDGET_HEIGHT_BY_INDEX -
      NATIVE_NEWS_WIDGET_HEIGHT -
      GAP * 2;

    const freeHeight = isCompact
      ? FREE_HEIGHT_FOR_COMPACT
      : FREE_HEIGHT_FOR_FULL_SCREEN;

    const hasNativeNewsWidget = useMemo(
      () => (isCompact ? freeHeight > NATIVE_NEWS_WIDGET_HEIGHT : true),
      [isCompact, freeHeight],
    );
    const hasSecondBanner = useMemo(() => {
      if (isCompact)
        return freeHeight - NATIVE_NEWS_WIDGET_HEIGHT - GAP > AD_BANNER_HEIGHT;

      if (freeHeight > AD_BANNER_HEIGHT)
        return freeHeight - NATIVE_NEWS_WIDGET_HEIGHT - GAP > AD_BANNER_HEIGHT;

      return false;
    }, [isCompact, freeHeight]);

    return (
      <div className={cn(s.container)}>
        <div className={cn(s.mainAdBlocks, !hasSecondBanner && s.sticky)}>
          <Ad
            name={Banners['240x400']}
            puids={puids}
            withoutMarginBottom
            isLazy={false}
          />
          {isCompact && !isMulticluster && (
            <RcmWidgetWithResource
              ref={rnetWidgetRef}
              resourceId={resourceId}
              clusterId={clusterId}
            />
          )}
          {hasNativeNewsWidget && (
            <NativeNewsWidget puids={puids} clusterIndex={clusterIndex} />
          )}
        </div>
        {hasSecondBanner && (
          <Ad
            name={Banners['240x400_2']}
            puids={puids}
            withoutMarginBottom
            className={s.sticky}
          />
        )}
      </div>
    );
  },
);
