import cn from 'classnames';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { PrivatePopup } from 'common/components/PrivatePopup';
import { RcmWidgetClusterFeed } from 'common/components/RCMWidget/components/RcmWidgetClusterFeed';
import { useClusterChangeUrl } from 'common/hooks/useClusterChangeUrl';
import { useClusterFeed } from 'common/hooks/useClusterFeed';
import {
  RCM_BLOCK_TYPE,
  useBlockInitAtd,
  usePageViewAtd,
} from 'common/hooks/useRcm';
import { selectClustersWithTopics } from 'common/redux/commonData/entries/selectors';
import { fetchClusterFeed } from 'common/redux/pages/cluster';
import {
  selectClusterPageClusterFeed,
  selectClusterPageCurrentClusterId,
} from 'common/redux/pages/cluster/selectors';
import {
  selectCustomClusterFeed,
  selectIsHumanCenteredStrategyStateActivated,
} from 'common/redux/runtime/selectors';
import { ArticleWrapper } from 'desktop/components/ArticleWrapper';
import { Layout } from 'desktop/components/Layout';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import s from './styles.module.css';

import 'desktop/css/banner--native-partner_widget.css';
import 'desktop/css/banner--native-footer_widget.css';
import 'desktop/css/banner--inpage_content.css';
import 'desktop/css/banner--native-article_redesign.css';

/** Ограничитель количества новостей в ленте */
const FEED_LENGTH = 5;
const BLUR_VALUE = 7;

/**
 * Страница кластера, обертка для списка новостей
 */
const ClusterPage = memo(() => {
  const dispatch = useAppDispatch();

  const currentClusterId = useAppSelector(selectClusterPageCurrentClusterId);
  const clusterFeed = useAppSelector(
    selectClusterPageClusterFeed,
    shallowEqual,
  );
  const clustersWithTopics = useAppSelector(
    (state) => selectClustersWithTopics(state, clusterFeed),
    shallowEqual,
  );

  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );
  const customFeedList = useAppSelector(selectCustomClusterFeed, shallowEqual);

  const firstClusterId = clusterFeed[0];

  const feedLength = Array.isArray(customFeedList)
    ? FEED_LENGTH + customFeedList.length
    : FEED_LENGTH;

  const lastFeedIndex = feedLength - 1;

  const nodesRefs = useRef<HTMLDivElement[]>([]);

  const onRef = useCallback((el: HTMLDivElement) => {
    const index = Number(el?.dataset.index);

    nodesRefs.current[index] = el;
  }, []);

  usePageViewAtd();
  useBlockInitAtd(RCM_BLOCK_TYPE.clusterFeedDesktop);

  const { triggerComponent, currentLoadingCluster } = useClusterFeed({
    feedLength,
    styles: s,
  });

  useClusterChangeUrl({ clusterFeed, clustersWithTopics, nodesRefs });

  useEffect(() => {
    nodesRefs.current = nodesRefs.current?.slice(0, feedLength);
  }, [feedLength]);

  useEffect(() => {
    const getClusterFeed = () => {
      dispatch(fetchClusterFeed({ clusterId: firstClusterId }));
    };

    getClusterFeed();
  }, [dispatch, firstClusterId]);

  const clustersList = clusterFeed.map(
    (id, index) =>
      clustersWithTopics.find((cluster) => cluster?.id === id) &&
      `${currentLoadingCluster}` !== id && (
        <ArticleWrapper
          key={id}
          clusterId={id}
          clusterIndex={index}
          isCurrentCluster={currentClusterId === id}
          onRef={onRef}
          topicId={clustersWithTopics[index]?.mainTopicId}
        />
      ),
  );

  if (triggerComponent) {
    clustersList.push(triggerComponent);
  }

  return (
    <Layout>
      <PrivatePopup clusterID={clusterFeed[0]} blur={BLUR_VALUE} />
      <RcmWidgetClusterFeed
        className={cn(s.widget, forceRedesign && s.redesign)}
        rcmBlockType={RCM_BLOCK_TYPE.desktopWidget}
        clusterId={clusterFeed[lastFeedIndex]}
        lastFeedIndex={lastFeedIndex}
      >
        {clustersList}
      </RcmWidgetClusterFeed>
    </Layout>
  );
});

export default ClusterPage;
