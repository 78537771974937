import cn from 'classnames';
import React, { useContext } from 'react';
import { shallowEqual } from 'react-redux';

import { Ad } from 'common/components/Ad';
import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { selectClusterPagePuids } from 'common/redux/pages/cluster/selectors';
import {
  selectIsRussia,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import { NativeNewsWidget } from 'desktop/components/Cluster/NativeNewsWidget';
import { useAppSelector } from 'store/hooks';

import { NarrowColumnContext } from '../NarrowColumn/context';
import { WidgetsColumn } from '../WidgetsColumn';

import s from './styles.module.css';

/** Высота рекламы у первого кластера в компактной версии */
const AD_HEIGHT_FIRST_FLOOR = 760;

/** Высота реламы у остальных кластеров в компактной версии */
const AD_HEIGHT = 600;

/** Высота блока с нативом */
const NATIVE_NEWS_WIDGET_HEIGHT = 230;

/** Отступ */
const GAP = 20;

type AdColumnPropsType = {
  clusterIndex: number;
  clusterId: CardData['id'];
};

/**
 * Колонка с рекламой.
 * @param props.clusterIndex – индекс кластера в бесконечке;
 * @param props.clusterId – id кластера.
 */
export const AdColumn = withErrorBoundary(function AdColumn({
  clusterIndex,
  clusterId,
}: AdColumnPropsType) {
  const puids = useAppSelector(selectClusterPagePuids, shallowEqual);
  const isRussiaRegion = useAppSelector(selectIsRussia);
  const isPromoWidgetEnabled = useAppSelector(selectPromoWidgetEnabled);
  const isPromoWidgetPlaceholderEnabled = useAppSelector(
    selectPromoWidgetPlaceholderEnabled,
  );

  const context = useContext(NarrowColumnContext);
  const fullHeight = context?.fullHeight ?? 0;

  const hasNativeNewsWidget =
    clusterIndex === 0
      ? NATIVE_NEWS_WIDGET_HEIGHT <= fullHeight - AD_HEIGHT_FIRST_FLOOR
      : NATIVE_NEWS_WIDGET_HEIGHT <= fullHeight - AD_HEIGHT - GAP;

  return (
    <div className={s.container}>
      {clusterIndex === 0 && isRussiaRegion && isPromoWidgetEnabled && (
        <Ad
          name={Banners.PromoWidget}
          puids={puids}
          isLazy={false}
          /* Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился */
          className={cn(s.promo, isPromoWidgetPlaceholderEnabled && s.visible)}
          preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
          disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
          loadedClassName={s.visible}
        />
      )}
      <Ad
        puids={puids}
        name={Banners['240x400']}
        withoutMarginBottom
        isLazy={false}
      />
      {hasNativeNewsWidget && (
        <div className={s.native}>
          <NativeNewsWidget puids={puids} clusterIndex={clusterIndex} />
        </div>
      )}
      <div className={s.widgets}>
        <WidgetsColumn clusterId={clusterId} adHeight={AD_HEIGHT_FIRST_FLOOR} />
      </div>
    </div>
  );
});
