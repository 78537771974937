import { useRef, useEffect, MutableRefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type WrapperResizeObserverCallback = (
  ref: MutableRefObject<Element | null>,
) => ResizeObserverCallback;

type UseObserverType = <T extends Element>(
  callback: ResizeObserverCallback | WrapperResizeObserverCallback | null,
  options?: {
    initialState?: T | null;
    shouldThrowRef?: boolean;
  },
) => MutableRefObject<T | null> | null;

/** Хук для отслеживания изменений размера элемента */
export const useResizeObserver: UseObserverType = (
  callback,
  options = {
    initialState: null,
    shouldThrowRef: false,
  },
) => {
  const observerRef = useRef(options.initialState || null);

  useEffect(() => {
    if (observerRef && observerRef.current && callback) {
      let resizeObserver: ResizeObserver | null = new ResizeObserver(
        // @ts-expect-error: ¯\_(ツ)_/¯
        options.shouldThrowRef
          ? (callback as WrapperResizeObserverCallback)(observerRef)
          : callback,
      );

      resizeObserver.observe(observerRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
          resizeObserver = null;
        }
      };
    }

    return undefined;
  }, [observerRef, callback, options.shouldThrowRef]);

  return callback ? observerRef : null;
};
