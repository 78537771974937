import cn from 'classnames';
import React, { useContext, useRef } from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { useWidgetReachGoal } from 'common/components/metrics/utils';
import { RamblerCurrencyConverter } from 'common/components/RamblerEmbeds/RamblerCurrencyConverter';
import { RcmWidgetWithResource } from 'common/components/RCMWidget/components/RcmWidgetWithResource';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import { CLUSTER_TYPE } from 'config/constants/cluster';
import { RecommendationBlock } from 'desktop/components/RecommendationBlock';
import { useAppSelector } from 'store/hooks';

import { NarrowColumnContext } from '../NarrowColumn/context';

import s from './styles.module.css';

type WidgetsColumnPropsType = {
  clusterId: CardData['id'];
  adHeight?: number;
  level?: number;
};

/** Отступ между блоками */
const GAP = 20;

/** Самая распространенная высота виджета, когда прилетают 2х-строчные заголовки */
const RESOURCE_WIDGET_HEIGHT_DEFAULT = 350;

/** Высота виджета рекомендаций "Материалы по теме" */
const REC_WIDGET_HEIGHT_DEFAULT = 665;

/** Высота виджетов RamblerEmbeds */
const EMBED_WIDGET_HEIGHT_DEFAULT = 300;

/**
 * Колонка с виджетами на странице кластера.
 * @param props.clusterId – id кластера;
 * @param props.adHeight - высота рекламы, которая вставляется перед виджетами.
 */
export const WidgetsColumn = withErrorBoundary(function WidgetsColumn({
  clusterId,
  adHeight = 0,
  level,
}: WidgetsColumnPropsType) {
  const cluster = useAppSelector(selectClusterById(clusterId), shallowEqual);
  const { type, gallery, manualTagIds, displayType, resourceId } =
    cluster || {};

  const isMulticluster = useAppSelector(
    selectManualTagIsMulticluster(manualTagIds, displayType),
  );
  const context = useContext(NarrowColumnContext);
  const fullHeight = context?.fullHeight ?? 0;

  const recNewsWidgetRef = useRef<HTMLDivElement>(null);
  const resourceNewsWidgetRef = useRef<HTMLDivElement>(null);

  const { reachGoalNewsClickCallback, widgetRef } = useWidgetReachGoal(level);

  const recNewsWidgetHeight =
    recNewsWidgetRef.current?.clientHeight ?? REC_WIDGET_HEIGHT_DEFAULT;
  const resourceNewsWidgetHeight =
    resourceNewsWidgetRef.current?.clientHeight ??
    RESOURCE_WIDGET_HEIGHT_DEFAULT;

  const hasGallery = type === CLUSTER_TYPE.photo && !!gallery;

  const hasResouceNewsWidget = hasGallery
    ? resourceNewsWidgetHeight <= fullHeight
    : resourceNewsWidgetHeight <= fullHeight - adHeight - GAP;

  const hasConverterWidget =
    EMBED_WIDGET_HEIGHT_DEFAULT <=
    fullHeight - adHeight - GAP - resourceNewsWidgetHeight - GAP;

  const hasExchangeWiget =
    EMBED_WIDGET_HEIGHT_DEFAULT <=
    fullHeight -
      adHeight -
      GAP -
      resourceNewsWidgetHeight -
      GAP -
      EMBED_WIDGET_HEIGHT_DEFAULT -
      GAP;

  const hasRecNewsWidget =
    recNewsWidgetHeight <=
    fullHeight -
      resourceNewsWidgetHeight -
      GAP -
      (EMBED_WIDGET_HEIGHT_DEFAULT - GAP) * 2;

  return (
    <>
      {!adHeight && hasRecNewsWidget && (
        <div ref={widgetRef}>
          <RecommendationBlock
            containerRef={recNewsWidgetRef}
            title="Материалы по теме"
            clusterId={clusterId}
            level={1}
            withImage
            onClick={reachGoalNewsClickCallback}
          />
        </div>
      )}
      {hasConverterWidget && <RamblerCurrencyConverter />}
      {hasExchangeWiget && (
        <div className={cn(s.block, s.exchangeWidget)}>
          Выгодный курс обмена
        </div>
      )}
      {!isMulticluster && hasResouceNewsWidget && (
        <RcmWidgetWithResource
          resourceId={resourceId}
          clusterId={clusterId}
          ref={resourceNewsWidgetRef}
        />
      )}
    </>
  );
});
