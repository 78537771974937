import React from 'react';
import { shallowEqual } from 'react-redux';

import { withErrorBoundary } from 'common/components/ErrorBoundary/withErrorBoundary';
import { RcmWidget } from 'common/components/RCMWidget';
import {
  WidgetLayoutTypeV3,
  WidgetTemplate,
} from 'common/components/RCMWidget/typings';
import { TopicsMenu } from 'common/components/TopicsMenu';
import { TopicsMenuRedesign } from 'common/components/TopicsMenu/Redesign';
import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { selectClusterPuids } from 'common/redux/commonData/entries/selectors';
import { StateTopicType } from 'common/redux/commonData/topics/typings';
import { selectClusterPageClusterFeed } from 'common/redux/pages/cluster/selectors';
import {
  selectIsHumanCenteredStrategyStateActivated,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { AdSuperfooterWithFallback } from 'desktop/pages/Cluster/components/AdSuperfooterWithFallback';
import { Article } from 'desktop/pages/Cluster/components/Article';
import { useAppSelector } from 'store/hooks';

import { Article as ArticleRedesign } from './components/Article';

import s from './styles.module.css';

type ArticleWrapperPropsType = {
  clusterId: ClusterData['id'];
  clusterIndex: number;
  isCurrentCluster: boolean;
  onRef: (el: HTMLDivElement) => void;
  topicId: StateTopicType['id'] | undefined | null;
};

/**
 * Компонент обертки кластера, в зависимости от флага отдает версию редизайна или старый кластер.
 * @param props.clusterId - id кластера;
 * @param proos.clusterIndex - индекс кластера;
 * @param props.isCurrentCluster - флаг, что это текущий кластер;
 * @param props.onRef - колбек для смены урла кластера;
 * @param props.topicId - id топика для меню топиков.
 */
export const ArticleWrapper = withErrorBoundary(function ArticleWrapper({
  clusterId,
  clusterIndex,
  isCurrentCluster,
  onRef,
  topicId,
}: ArticleWrapperPropsType) {
  const projectId = useAppSelector(selectProjectId);
  const clusterPuids = useAppSelector(
    selectClusterPuids(clusterId),
    shallowEqual,
  );
  const clusterFeed = useAppSelector(
    selectClusterPageClusterFeed,
    shallowEqual,
  );
  const forceRedesign = useAppSelector(
    selectIsHumanCenteredStrategyStateActivated,
  );

  const isEvenIndex = clusterIndex % 2 === 0;

  const renderRnetOrAdBlocks = () => {
    if (!isEvenIndex) {
      return (
        <RcmWidget
          rcmBlockType={
            forceRedesign
              ? RCM_BLOCK_TYPE.rnetHorizontalDesktopRedesign
              : RCM_BLOCK_TYPE.rnetHorizontalDesktop
          }
          templateName={WidgetTemplate.v3}
          layoutType={WidgetLayoutTypeV3.Horizontal}
          clusterId={clusterFeed[clusterIndex]}
        />
      );
    }

    return (
      <AdSuperfooterWithFallback
        puids={clusterPuids}
        index={clusterIndex}
        clusterId={clusterFeed[clusterIndex]}
      />
    );
  };

  return forceRedesign ? (
    <div
      ref={onRef}
      data-index={clusterIndex}
      className={s.articleWrapperRedesign}
    >
      {/** Вынести полоску с топиками прочь из компонента при редизайне, ибо полоска только для первого кластера */}
      {clusterIndex === 0 && (
        <TopicsMenuRedesign projectId={projectId} activeTopicId={topicId} />
      )}
      <ArticleRedesign
        clusterId={clusterId}
        index={clusterIndex}
        isCurrent={isCurrentCluster}
      />
      {renderRnetOrAdBlocks()}
    </div>
  ) : (
    <div ref={onRef} data-index={clusterIndex} className={s.articleWrapper}>
      <TopicsMenu
        projectId={projectId}
        activeTopicId={topicId}
        top100={`topics_menu::line_${clusterIndex}`}
      />
      <Article
        clusterId={clusterId}
        index={clusterIndex}
        isCurrent={isCurrentCluster}
      />
      {renderRnetOrAdBlocks()}
    </div>
  );
});
