import cn from 'classnames';
import React, {
  ReactNode,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Ad } from 'common/components/Ad';
import {
  generatePlayerId,
  generatePlayerIdRoot,
} from 'common/components/VideoPlayer/utils';
import { useSafeMediaQuery } from 'common/hooks/useSafeMediaQuery';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectClusterById } from 'common/redux/commonData/entries/selectors';
import { selectManualTagIsMulticluster } from 'common/redux/commonData/manualTags/selectors';
import {
  selectIsRussia,
  selectPromoWidgetEnabled,
  selectPromoWidgetPlaceholderEnabled,
} from 'common/redux/runtime/selectors';
import Banners from 'config/constants/banner/banners';
import { PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT } from 'config/constants/banner/contants';
import { PuidsType } from 'config/constants/common';
import { WINDOW_WIDTH_COMPACT } from 'config/constants/css';
import { ColumnsLayout } from 'desktop/pages/Cluster/components/ColumnsLayout';
import { NARROW_AREA_TYPES } from 'desktop/pages/Cluster/components/ColumnsLayout/NarrowColumn';

import { AdSwitcher } from '../AdSwitcher';
import { WidgetsSwitcher } from '../WidgetsSwitcher';

import { MainClusterContentSizeContextProvider } from './context';

import s from './styles.module.css';

type ClusterLayoutPropsType = {
  clusterId: ClusterData['id'];
  index: number;
  resourceId: ClusterData['resourceId'];
  puids: PuidsType | undefined;
  isWideHeader?: boolean;
  children: ReactNode;
};

/**
 * Компонент содержимого кластера.
 * @param clusterId - id отображаемого кластера.
 * @param index - индекс кластера в бесконечке.
 * @param resourceId - id основного источника кластера.
 * @param projectId - id проекта к которому относится основной топик кластера
 * @param puids - рекламные пуиды
 * @param activeTopicId - id топика к которому относится кластер
 * @param isWideHeader - флаг что заголовок и галерея кластера занимают 2 колонки
 */
export const ClusterLayout = memo(
  ({
    children,
    clusterId,
    index,
    isWideHeader = false,
    resourceId,
    puids,
  }: ClusterLayoutPropsType) => {
    const cluster = useSelector(selectClusterById(clusterId), shallowEqual);
    const isMulticluster = useSelector(
      selectManualTagIsMulticluster(
        cluster?.manualTagIds,
        cluster?.displayType,
      ),
    );
    const isRussiaRegion = useSelector(selectIsRussia);
    const isPromoWidgetEnabled = useSelector(selectPromoWidgetEnabled);
    const isPromoWidgetPlaceholderEnabled = useSelector(
      selectPromoWidgetPlaceholderEnabled,
    );

    const [fullHeight, setFullHeight] = useState(0);

    const contentRef = useRef<HTMLDivElement>(null);

    const top100Attribute = useTop100AttributeWithValue(`cluster_${index + 1}`);

    const isCompact = useSafeMediaQuery({ maxWidth: WINDOW_WIDTH_COMPACT });

    const value = useMemo(() => ({ fullHeight }), [fullHeight]);

    useEffect(() => {
      // Вычисляем размер тела кластера, чтобы передавать в виджеты и там определять умещается ли виджет подписки
      const contentHeight = contentRef.current?.clientHeight ?? 0;

      setFullHeight(contentHeight);
    }, [isCompact]);

    return (
      <article
        className={s.article}
        id={generatePlayerIdRoot(generatePlayerId(clusterId, index))}
        {...top100Attribute}
      >
        <ColumnsLayout
          isWideHeader={isWideHeader}
          isMulticluster={isMulticluster}
        >
          <MainClusterContentSizeContextProvider value={value}>
            <div
              className={cn(
                s.mainContent,
                isWideHeader && s.mainContentWideHeader,
              )}
              ref={contentRef}
            >
              {/* Основной контент */}
              {children}

              {/* Колонка виджетов для кластера с галереей */}
              {!isCompact && isWideHeader && (
                <ColumnsLayout.NarrowColumn
                  area={NARROW_AREA_TYPES.widgets}
                  needAddProps
                >
                  <WidgetsSwitcher clusterIndex={index} clusterId={clusterId} />
                </ColumnsLayout.NarrowColumn>
              )}
            </div>

            {/* Колонка виджетов */}
            {!isCompact && !isWideHeader && (
              <ColumnsLayout.NarrowColumn
                area={NARROW_AREA_TYPES.widgets}
                needAddProps
              >
                <WidgetsSwitcher clusterIndex={index} clusterId={clusterId} />
              </ColumnsLayout.NarrowColumn>
            )}

            {/* Колонка с рекламой */}
            <ColumnsLayout.NarrowColumn
              area={NARROW_AREA_TYPES.aside}
              needAddProps
              className={s.promoWidgetLayout}
            >
              {isRussiaRegion && isPromoWidgetEnabled && index === 0 && (
                <Ad
                  name={Banners.PromoWidget}
                  puids={puids}
                  isLazy={false}
                  /* Рисуем отступ снизу если плейсхолдер виджета включен или он отрендерился */
                  className={cn(
                    s.promo,
                    isPromoWidgetPlaceholderEnabled && s.visible,
                  )}
                  preloadHeight={PROMO_BANNER_DESKTOP_PLACEHOLDER_HEIGHT}
                  disablePlaceholder={!isPromoWidgetPlaceholderEnabled}
                  loadedClassName={s.visible}
                />
              )}
              <AdSwitcher
                puids={puids}
                isCompact={isCompact}
                clusterId={clusterId}
                resourceId={resourceId}
                clusterIndex={index}
              />
            </ColumnsLayout.NarrowColumn>
          </MainClusterContentSizeContextProvider>
        </ColumnsLayout>
      </article>
    );
  },
);
